import React from "react";
import { injectIntl } from "gatsby-plugin-intl";

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Section from "../../components/section";
import Banner from "../../components/banner";

import Faq from "../../components/faq";
import "../../styles/faqs.less";

class Faqs extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {openFaq: null};
    }

    handleOpenFaq = (id) =>
    {
        if (this.state.openFaq === id)
        {
            this.setState({openFaq: null});
            return;
        }

        this.setState({openFaq: id});
    }
    render()
    {
        const faqs = 
        [
            {
                id: 1,
                title: this.props.intl.formatMessage({id: "What is BusinessUp?"}),
                description: this.props.intl.formatMessage({id: "BusinessUp is a platform that allows you to manage the entrepreneurship and investment in your ecosystem, generating value for your organization and allowing the development of many projects."})
            },
            {
                id: 2,
                title: this.props.intl.formatMessage({id: "How much does the platform cost?"}),
                description: this.props.intl.formatMessage({id: "FAQ_COST"})
            },
            {
                id: 3,
                title: this.props.intl.formatMessage({id: "Is there a trial period?"}),
                description: this.props.intl.formatMessage({id: "We only charge for each activated platform. That is, you can register and start configuring your platforms to your liking and without any commitment, without entering your credit card or making any payment. Once you decide to activate your platform to start working with it, the trial period will end."})
            },
            {
                id: 4,
                title: this.props.intl.formatMessage({id: "Which organizations can use BusinessUp?"}),
                description: this.props.intl.formatMessage({id: "FAQ_ORGANIZATIONS"})
            },
            {
                id: 5,
                title: this.props.intl.formatMessage({id: "Who owns the data I generate on my platforms?"}),
                description: this.props.intl.formatMessage({id: "To you and you alone, we do not own any of the data you generate within your own platforms. At Adventurees Alliance we strictly comply with the General Data Protection Regulation of the European Union (GDPR) and protect your privacy and that of your clients."})
            },
            {
                id: 6,
                title: this.props.intl.formatMessage({id: "How many platforms can I create?"}),
                description: this.props.intl.formatMessage({id: "You can create as many platforms as you want once you sign up for Advenurees Alliance. You can try them for free until the moment you decide to activate them, when we will pass you the subscription for each platform you have active."})
            },
            {
                id: 7,
                title: this.props.intl.formatMessage({id: "Do I need technical knowledge?"}),
                description: this.props.intl.formatMessage({id: "No, our platform is very easy to use, configure and customize, so you don't need to have any special technical knowledge. If you want to go even deeper into the personalization of your platform, contact us and we will help you."})
            },
            {
                id: 8,
                title: this.props.intl.formatMessage({id: "Can I customize my platform?"}),
                description: this.props.intl.formatMessage({id: "Yes, our platform is designed so that each customer can customize it to their liking and make it their own. Defining your own style, colors, logo and so on is very simple."})
            },
            {
                id: 9,
                title: this.props.intl.formatMessage({id: "Can I use my own domain?"}),
                description: this.props.intl.formatMessage({id: "Apart from the aesthetic customization, the platform allows you to change the default domain to one of your own that you have previously purchased, and it is also very easy to do. (As long as you have activated your subscription)."})
            },
            {
                id: 10,
                title: this.props.intl.formatMessage({id: "Does BusinessUp include the Goldsmith® model?"}),
                description: this.props.intl.formatMessage({id: "Yes, when you hire our platform you get access to the Goldsmith® model and all the potential this tool can bring to you."})
            },
            {
                id: 11,
                title: this.props.intl.formatMessage({id: "What languages are available?"}),
                description: this.props.intl.formatMessage({id: "BusinessUp is available in Spanish, English and Portuguese."})
            },
            {
                id: 12,
                title: this.props.intl.formatMessage({id: "Can I hire a platform totally tailored to my needs?"}),
                description: this.props.intl.formatMessage({id: "From Adventurees we offer the possibility of making ad-hoc platforms, contact us to specify the development and budget."})
            },
            {
                id: 13,
                title: this.props.intl.formatMessage({id: "Can I create crowdfunding platforms with BusinessUp?"}),
                description: this.props.intl.formatMessage({id: "Yes, BusinessUp allows you to create your own crowdfunding platform. If it is a donation crowdfunding you don't need a license of any kind and you can operate perfectly. In the case of an equity or lending crowdfunding, you must be accredited by the CNMV or, if you prefer, you can channel your projects through our own crowdfunding platform, Adventurees. In these cases it is best that you contact us for a customized process and assistance."})
            }
        ];
        return <Layout businessUp test>
            <SEO 
                title={this.props.intl.formatMessage({id: "FAQ - BusinessUp by Adventurees"})} 
                description={this.props.intl.formatMessage({id: "Find the answer to BusinessUp FAQs from Adventurees"})}
                location={this.props.location}
            />
        
            <Banner 
                className="banner" 
                filename="clients-banner.png"
                title={this.props.intl.formatMessage({id: "Frequently asked questions"})} 
                subtitle={this.props.intl.formatMessage({id: "Find the answer to BusinessUp FAQs"})}
            />
        
            <Section title={this.props.intl.formatMessage({id: "Frequently asked questions"})}>
                <div className="faq-section">
                    <ul className="list-faq">
                        {faqs.map((faq) => {
                            const isOpen = faq.id === this.state.openFaq;
                            return <li key={faq.id} className={isOpen && "open"}>
                                <Faq isOpen={isOpen} data={faq} handleOpenFaq={this.handleOpenFaq}/>
                            </li>
                        })}
                    </ul>
                </div>
            </Section>
        </Layout>
    }
  
}

export default injectIntl(Faqs);