import React from "react";
import PropTypes from 'prop-types';


const Faq = (props) => {
  return <>
  <a href="javascript:void(0);" onClick={() => props.handleOpenFaq(props.data.id)}>
      <span>{props.isOpen ? "-" : "+" }</span>
      {props.data.title}
  </a>
  <div className="faq-desc" style={props.isOpen ? {} : {display: "none"}} dangerouslySetInnerHTML={{__html: props.data.description}}></div>
</>
}

Faq.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  isOpen: PropTypes.bool
}

export default Faq;

