import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl, Link } from "gatsby-plugin-intl";

const Button = (props) => (
  <div className="form-trigger">
    {props.internalUrl &&
      <Link className={props.className} to={props.internalUrl}>{props.intl.formatMessage({id: props.text})}</Link>
    }
    {props.url &&
      <a target={props.target} href={props.url} className={props.className}>
        {props.intl.formatMessage({id: props.text})}
      </a>
    }
    {props.note && <p className="note">{props.intl.formatMessage({id: props.note})}</p>}
  </div>
)

Button.propTypes = {
    internalUrl: PropTypes.string,
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    url: PropTypes.string,
	target: PropTypes.string,
	note: PropTypes.string
};

export default injectIntl(Button);